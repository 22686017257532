






























































































































































































































































































import LocationService from "@/services/LocationService";
import AuthService from "../services/AuthService";
import moment from "moment";
import "moment/locale/de";
import { Component, Ref, Vue, Watch } from "vue-property-decorator";
import { BModal } from "bootstrap-vue";

@Component
export default class Location extends Vue {
  private location: any = {};
  private searchtext: string = "";
  private currentPage: number = 2;
  private pagesize: number = 10;
  private sortBy = "name";
  private sortDesc = false;
  private sortDirection = "asc";
  private selectedRow: any = null;
  private deleteLocation: any = null;
  @Ref("deleteError") readonly errorDialog!: BModal;

  valid(item: any) {
    return (
      this.state(item.address.street) &&
      this.state(item.address.zip) &&
      this.state(item.address.city) &&
      this.state(item.name) &&
      this.state(item.contact.phone)
    );
  }

  state(value: any) {
    if (!value) {
      return false;
    }

    return value.length > 0;
  }

  numberstate(value: number, min: number, max: number) {
    if (!value) {
      return false;
    }

    return value >= min && value <= max;
  }

  expandAdditionalInfo(row: any) {
    if (this.selectedRow) {
      this.selectedRow._showDetails = false;
    }
    row._showDetails = !row._showDetails;
    this.selectedRow = row;
  }

  add() {
    this.selectedRow = {
      address: { city: "Frankfurt" },
      contact: {},
      _showDetails: true,
    };
    this.location.content.unshift(this.selectedRow);
  }

  handlePageSizeChange() {
    this.currentPage = 1;
    this.reload();
  }

  @Watch("currentPage")
  handlePageChange() {
    this.reload();
  }

  @Watch("searchtext")
  search() {
    this.reload();
  }

  save(event: any) {
    if (event.uuid) {
      LocationService.update(event.uuid, event).then((response) =>
        this.reload()
      );
    } else {
      LocationService.create(event).then((response) => this.reload());
    }
  }

  remove(event: any) {
    LocationService.delete(event.uuid)
      .then((response) => this.reload())
      .catch((err) => this.errorDialog.show());
  }

  isLoggedIn() {
    return AuthService.isLoggedIn();
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  reload() {
    LocationService.findByTitle(
      this.searchtext,
      this.currentPage - 1,
      this.pagesize,
      `${this.sortBy},${this.sortDirection}`
    ).then((response) => {
      this.location = response.data;
      this.selectedRow = null;
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);
  }
}
